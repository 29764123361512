import { db } from '@/config/firebase';

async function retrieve(filterBy = {}) {
    let faqsObj = {};
    let query = db.collection('faqs');

    if (filterBy.category) {
        query = query.where("category", "==", filterBy.category);
    }

    query = query.orderBy('dateCreated', 'desc');

    const querySnapshot = await query.get();
    querySnapshot.forEach(doc => {
        let faqObj = doc.data();
        faqObj['id'] = doc.id;
        faqsObj[doc.id] = faqObj;
    });

    return faqsObj;
}

async function create(faq) {
    let query = db.collection('faqs');
    return query.add(faq);
}

async function update(faq) {
    let query = db.collection('faqs').doc(faq.id);
    return query.set(faq, { merge: true });
}

async function undoDelete(id) {
    let query = db.collection('faqs').doc(id);
    return query.set({
        isDeleted: false,
        dateDeleted: null
    }, { merge: true });
}

async function softDelete(id) {
    let query = db.collection('faqs').doc(id);
    return query.set({
        isDeleted: true,
        dateDeleted: Date.now()
    }, { merge: true });
}

async function forceDelete(id) {
    let query = db.collection('faqs').doc(id);
    return query.delete();
}

export default {
    retrieve,
    create,
    update,
    softDelete,
    undoDelete,
    forceDelete
}