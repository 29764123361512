<template>
	<b-modal id="reorder-faqs" ref="reorder-faqs-modal" title="Re-order FAQs" @ok.prevent="saveOrdering"
		ok-title="Update List" size="md" no-close-on-backdrop no-close-on-esc hide-backdrop>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<div class="row">
			<div class="col-md-12">
				<b-alert show variant="info">Click and drag to reoder items</b-alert>
				<draggable v-model="items" ghost-class="ghost" class="list-group">
					<transition-group>
						<div class="
								list-group-item
								d-flex
								justify-content-between
								align-items-center
							" v-for="item in orderedList" :key="item.id">
							{{ item.question }}
						</div>
					</transition-group>
				</draggable>
			</div>
		</div>
	</b-modal>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import EventBus from '@/shared/event-bus';
import draggable from 'vuedraggable';
import _ from 'lodash';
import asyncForEach from '@/utils/asyncForEachUtil';
import api from '@/api/faqApi';

export default {
	name: 'reorder-faqs',
	components: {
		draggable,
		Loading,
	},
	data() {
		return {
			enabled: true,
			items: [],

			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		orderedList() {
			return this.items.map((item, index) => {
				return {
					...item,
					order: index + 1,
				};
			});
		},
	},
	mounted() {
		EventBus.$on('showFAQsReorderingDialog', (items) => {
			this.items = _.orderBy(items, ['order'], ['asc']);
			this.$bvModal.show('reorder-faqs');
		});
	},
	methods: {
		async saveOrdering() {
			this.isLoading = true;

			try {
				await asyncForEach(this.orderedList, async (item) =>
					await api.updateFaq(item, this.loggedUser.id)
				);

				this.$bvModal.hide('reorder-faqs');
				this.$toaster.success('Reordering FAQs has been updated!');
				EventBus.$emit('onSaveFAQ');
			} catch (_error) {
				this.$toaster.error('Error reordering FAQs.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('showFAQsReorderingDialog');
	},
};
</script>

<style scoped>
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}

.list-group-item {
	cursor: pointer;
}
</style>